import 'owl.carousel';
import 'animate.css';

(function($) {
	if ($(".brand_slider.slide_true")[0]) {
		$(document).ready(function () {
			var carousel = $(".brand_slider .owl-carousel");
			carousel.owlCarousel({
				autoplay: true,
				autoplayTimeout: 6000,
				autoplayHoverPause: true, 
				loop: true,
				dots: false, 
				responsiveClass: true,
				responsive: {
					0: {
						items: 3
					},
					992: {
						items: 6
					}
				}
			});
		}); 
	}

	if ($(".testimonial_block")[0]) {
		$(document).ready(function () {
			var carousel = $(".testimonial_block .owl-carousel");
			carousel.owlCarousel({
				autoplay: true,
				autoplayTimeout: 6000,
				autoplayHoverPause: true, 
				loop: true,
				dots: true, 
				responsiveClass: true,
				responsive: {
					0: {
						items: 1
					}
				}
			});
		}); 
	}

	if ($(".arcus_key_features")[0]) {
		$(document).ready(function () {
			var carousel = $(".arcus_key_features .owl-carousel");
			carousel.owlCarousel({
				autoplay: true,
				autoplayTimeout: 6000,
				autoplayHoverPause: true, 
				loop: true,
				dots: true, 
				items: 1
			});
		}); 
	}

	if ($(".single_sector_wrapper .multi_video_carousel")[0]) {
		$(document).ready(function () {
			var carousel = $(".single_sector_wrapper .multi_video_carousel .owl-carousel");
			carousel.owlCarousel({
				autoplay: false,
				loop: true,
				dots: true,
				nav: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					}
				}
			});
		}); 
	}

	if ($(".home_wrapper .top_image .custom_carousel")[0]) {
		$(document).ready(function () {
			var carousel = $(".home_wrapper .top_image .owl-carousel");
			carousel.owlCarousel({
				autoplay: true,
				autoplayTimeout: 6000,
				autoplayHoverPause: true, 
				loop: true,
				dots: true, 
				mouseDrag: false,
				items: 1
			});
		}); 
	}
})(jQuery);