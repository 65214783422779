// New Menu Burger Menu

(function($) {
  var Menu = {

    el: {
      ham: $('.burger_menu'),
      animate_burger: $('.burger_menu')
    },

    init: function () {
      Menu.bindUIactions();
    },

    bindUIactions: function () {
      Menu.el.ham
        .on(
          'click',
          function (event) {
            Menu.activateMenu(event);
            event.preventDefault();
          }
        );
    },

    activateMenu: function () {
      Menu.el.ham.toggleClass('menu_close');
      Menu.el.animate_burger.toggleClass('clicked');
      $('.mobile_menu').toggleClass('slide_open');
      $('.color_match_btn').removeClass('active_color');
    }
  };

  Menu.init();

  $(".parent_link_items").click(function() {
    $(".parent_link_items").removeClass("parent_open");
    $(this).addClass("parent_open");
    $(".arrow").removeClass("open_arrow");
    $(this).find(".arrow").addClass("open_arrow");
    $(".dropdown_outer").removeClass("dropdown_active");
    $(".dropdown_outer").removeClass("fadeIn");
    $(this).find(".dropdown_outer").addClass("dropdown_active");
    $(this).find(".dropdown_outer").addClass("fadeIn");
  });

  $(".arrow").click(function() {
    $("parent_link_items").removeClass("parent_open");
    $(this).removeClass("open_arrow");
  });

  // Has Children hover set active class incase you want to use an triangle
  $("li.has_children").hover(
    function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    },
    function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    }
  );

  // Color match menu
  $(".color_match_btn").click(function() {
    $(".quick_order_mobile").toggleClass("active");
    $(this).toggleClass("active_color");
    $('.mobile_menu').removeClass('slide_open');
    $('.burger_menu').removeClass('clicked');
  });

  if ($('.burger_menu').length) {
    $('.burger_menu').toggleClass('show');
  }

  // Scroll back to the top after opening menu
  if ($('.color_match_btn').length) {
      var scrollTrigger = 100, // px
          backToTop = function () {
              var scrollTop = $(window).scrollTop();
              if (scrollTop > scrollTrigger) {
                  $('.color_match_btn').addClass('show');
              } else {
                  $('.color_match_btn').removeClass('show');
              }
          };
      backToTop();
      $(window).on('scroll', function () {
          backToTop();
      });
      $('.color_match_btn').on('click', function (e) {
          e.preventDefault();
          $('html,body').animate({
              scrollTop: 0
          }, 700);
      });
  }
})(jQuery);