(function($) {
  
  function createCookie(name, value, days) {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
  }

  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
      end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
  }

  // Some times you get random p tags that create space
  $('p:empty').remove();

  // Mobile menu toggle
  $(".mobile_search").on("click", function () {
    $(".mobile_search_menu").toggleClass("mobile_search_menu_open");
    $(".mobile_menu").removeClass("slide_open");
  });

  $(".burger_menu").on("click", function () {
    $(".mobile_search_menu").removeClass("mobile_search_menu_open");
  });

  // Remove br from top nav
  $(document).ready(function () {
    if ($(window).width() < 992) {
      $(".inner_bars span").find('br').remove();
    }
  });

  // Desktop Search
  $(".desktop_search_icon").on("click", function () {
    $(".search_area").toggleClass("active");
    $(this).addClass("active");
  });

  // Accordion
  $(document).ready(function() {
    $(".tab_inner").on("click", function() {
      //console.log("Clicked tab");
      $(this).next(".content").toggleClass("active");
      $(this).find(".arrow").toggleClass("turn_arrow");
    });
  });

  // Show and hide faqs
  $(".sector_inner").on("click", function() {
    var sectors_to_show = $(this).attr("data-attr");

    $(".sector_inner").removeClass("active_sector");
    $(this).addClass("active_sector");

    $(".faq_contents .active_hidden_section").removeClass("active_hidden_section fadeOut");
    $("." + sectors_to_show).addClass("active_hidden_section fadeIn");
  });

  // Go to Faq
  $(".target").click(function() {

    var winW = $(window).width();

    // console.log("clicked");
    // console.log(winW);

    if ($(window).width() < 767)
      {
        $([document.documentElement, document.body]).animate({
          scrollTop: $("#content_section").offset().top-30
      }, 1000);

      } else {

          $([document.documentElement, document.body]).animate({
          scrollTop: $("#content_section").offset().top-200
      }, 1000);

      }
  });

  // Home page top buttons
  $(".blocks .top_row").on("click", function() {
    $(".blocks .block").removeClass("active");
    $(this).closest(".block").addClass("active");
  });

  // Home page top buttons
  $(".blocks .top_row").on("click", function() {
    $(".blocks .block").removeClass("active");
    $(this).closest(".block").addClass("active");
  });

  // Nav buttons
  $("header .menu_item.has_dropdown_area").hover(function(){
    $(this).find('.dropdown_area').addClass('active');
  }, function(){
    $(this).find('.dropdown_area').removeClass('active');
  });

  // Nav buttons
  $("header .menu_item.has_dropdown_area").hover(function(){
    $(this).find('.dropdown_area').addClass('active');
  }, function(){
    $(this).find('.dropdown_area').removeClass('active'); 
  });

  $(".mobile_menu .menu_item.has_dropdown_area").on("click", function() {
    if ($(this).find('>a').hasClass('active')) {
      $(this).find('>a').removeClass('active');
      $(this).find('.dropdown_area').removeClass('active');
    } else {
      $(this).find('>a').addClass('active');
      $(this).find('.dropdown_area').addClass('active');
    }
  });

  $(".tabs_area .tab").on("click", function() {
    var attr_to_select = $(this).attr("data-attr");

    $(".tabs_area .tab").removeClass("active");
    $(".tab_inner").removeClass("active");

    $(this).addClass("active");
    $(".tab_inner." + attr_to_select).addClass("active");
  });

  if ($(".sector_page")[0]) {
    // trigger the modal based on a settimeout
    $(document).ready(function () {
      var myCookie = getCookie('disable_email_popup');
      if (myCookie == null) {
        setTimeout(function(){
          $(".capture_form_popup").addClass("active");
          // first time round - create the cookie
          createCookie('disable_email_popup', true, 30);
        }, 10000); // 10 seconds
      }
    });
    // close the modal
    $(document).on("click", ".capture_form_popup .close_btn", function() {
      $(".capture_form_popup").removeClass("active");
    });
    //additionals
    $(document).on("change", ".capture_form_popup #choose_an_industry", function() {
      console.log("Changed");

      var selected_val = $(this).find(":selected").val();
      $(".choose_a_job").removeClass("active");
      $("#" + selected_val).addClass("active");
    });
  }

  //lightbulb back button feature
  if ($(".job_role_page")[0]) {
    $(document).ready(function () {
      var myCookie = getCookie('enable_lightbulb_return_button');
      if (myCookie == null) {
        // first time round - create the cookie
        createCookie('enable_lightbulb_return_button', window.location.href, 30);
      }
    });
  }

  $("#lightbulb_close").on("click", function() {
    eraseCookie('enable_lightbulb_return_button');
    window.location.reload();
  });

  $(".content-left>div").on("click", function() {
    let attr = $(this).attr("data-attr");

    $(".content-right .content_inner>div").removeClass("active");
    $("." + attr).addClass("active");
  });

  $(".arcus_button").on("click", function() {
    $(".find_form_popup").addClass("active");
  });

  $(document).on("click", ".find_form_popup .close_button", function() {
    $(".find_form_popup").removeClass("active");
  });

  $(".mobile_menu .close_button").on("click", function() {
    $(".mobile_menu").removeClass("slide_open");
  });

})(jQuery);